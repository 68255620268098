/** @format */

.schedules-header {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;

  color: #000000;
}
.button-schedule-page {
  border: 10px solid #0000;
  padding: 0 80px;
  border-radius: 30px;
  margin-top: 4%;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}
#link-to-google-calendar {
  display: block;
  margin: 0 auto;
}
#link-to-shuffle-calendar,
#ShuffleClaendar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  width: 398px;
  height: 60px;
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 30px;
  text-decoration: none;
  margin-top: 50px;
  margin-left: 150px;
}
.todoapp.stack-large.text-center {
  margin-top: 75px;
}
.schedules-header-2 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #000000;
}
.modal-60w {
  width: 10%;
}
.Open-Google-Calendar {
  border: 2px solid #000000;
  border-radius: 30px;

  margin-left: 6%;
  margin-bottom: 6%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  width: 398px;
  height: 60px;
  background: #ffffff;
  border-radius: 30px;
  text-decoration-line: underline;
  text-decoration-color: #000000;
  margin-top: 50px;

  color: #000000;
  text-decoration: none;
}
.Back-To-Scheduling {
  border: 2px solid #000000;
  border-radius: 30px;
  text-decoration-line: none;
  margin-left: 6%;
  margin-bottom: -6%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  width: 398px;
  height: 60px;
  background: #000000;
  border-radius: 30px;
  text-decoration: none;
  margin-top: 50px;
  text-decoration: none;
}
.Success-Modal-h1 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin-top: 12%;

  color: #000000;
}
