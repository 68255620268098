/** @format */

@use '../../Typography/typography.scss' as *;
@use '../../reset.scss' as *;
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  width: 100vw;
  padding: 0 130px 0 135px;
  // background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;

  &.active {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }

  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  // box-shadow: 0px 100px 91px 44px rgba(245, 163, 202, 0.2);

  &__Header {
    // width: 296px;
    height: 28px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }

  &__Right {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 360px;
  }

  &__Right--Login,
  &__Right--Contact {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-right: 10%;

    color: #000000;

    &:hover {
      cursor: pointer;
    }
  }

  &__Right--Signup {
    width: 200px;
    height: 50px;

    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    color: #ffffff;

    background: #000000;
    box-sizing: border-box;
    border-radius: 30px;
  }

  &__Right--Hamburger {
    background: #ffffff;
    margin-left: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  #contact-form-row {
    width: 80%;
  }

  //   &__dropdown--Menu {
  //     background: #ffffff;
  //   }

  //   &__Nav--Links {
  //     font-family: "Work Sans";
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 18px;
  //     line-height: 21px;
  //     text-align: center;
  //     text-decoration: none;
  //     color: #000000;

  //     &:hover {
  //       background-color: #979090;
  //       cursor: pointer;
  //     }
  //   }
  // }

  // #navbar-menu-drop {
  //   background-color: white;
  //   color: black;
  //   border: 2px solid #000000;
  //   border-radius: 12px;
  //   padding: 13px 55px;
  //   padding-left: 10px;
  //   font-family: "Work Sans";
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 20px;
  //   line-height: 23px;
  //   margin-top: 15px;
  //   margin-right: 18px;
  // }

  // #navbar-link-drop {
  //   padding: 15px 55px;
  //   padding-left: 10px;
  //   font-family: "Work Sans";
  //   font-style: normal;
  //   font-weight: bold;
  //   font-size: 20px;
  //   line-height: 23px;
  // }
  // #navbar-link-drop:hover {
  //   background-color: #d5deff;
  // }

  // #navbar-link:hover {
  //   background-color: #d5deff;
  //   color: #000000;
  // }
}
#scheduleTimeLink {
  padding-top: 13px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
}
.my-time-logo {
  position: absolute;
  width: 102px;
  height: 92px;
  left: 102px;
  top: 2px;
}
.my-time-text {
  position: absolute;
  width: 113px;
  height: 35px;
  left: 209px;
  top: 31px;
}
