/** @format */

@use '../../Typography/typography.scss' as *;
@use '../../reset.scss' as *;
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Scheduling {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Wrapper for all 3 top header/titles
  &__Header--Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  // For both main headers
  &__Header {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #000000;
  }

  &__Input--Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }

  // smaller sub header
  &__Sub-Header {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-top: 30px;
    color: #000000;
  }

  // First top rectangle
  &__Rectangle--Top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 920px;
    height: 101px;
    border: 1px solid #9e9e9e;
    background-color: #ffffff;
    margin-top: 50px;
    margin-bottom: -1px;
  }

  // Light Buld and Heading in first rectangle
  &__Rectangle--Header {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #000000;

    // Just the light bulb
    &-Icon {
      margin-right: 10px;
    }
  }

  &__Rectangle--Header-Wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }

  &__Rectangle--Left-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  &__Rectangle--Right-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  &__Iconify--Icon {
    font-size: 35px;
  }

  &__Iconify--Trash {
    font-size: 35px;
    margin-right: 40px;
  }

  &__Iconify--Dropdown {
    font-size: 55px;
    margin-left: 40px;
  }

  &__Iconify--Drag {
    font-size: 55px;
    // margin-right: 40px;
  }

  // Middle 2 rectangle containers
  &__Rectangle--Middle {
    display: flex;
    flex-direction: column;
    justify-content: flex;
    align-items: flex-start;
    width: 843px;
    // height: 240px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 48px;
    margin: 15px;

    // icon and title wrapper in middle rectangles
    &-IconsandTitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    // Icon and activity title wrapper
    &-Activity-Wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 35px;
    }

    // Just the header for the activity
    &-Header {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      color: #000000;
      margin-left: 20px;
    }
  }

  // Container for the dropdown title and input
  &__Input--Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 30px;
  }

  &__Input--Header {
    font-family: "Work Sans";
    font-style: medium;
    font-size: 24px;
    color: #000000;
    line-height: 28px;
    line-height: 100%;
    text-align: left;
    vertical-align: top;
    margin-bottom: 40px;
  }

  &__Input--Dropdown {
    margin-top: 20px;
  }

  // The bottom shaded rectangle
  &__Rectangle--Footer {
    width: 920px;
    height: 140px;
    background-color: #f8f8f8;
  }
}

.preferences-container {
  background-color: #181717;

  margin-top: 90px;
}

// .dropdown-basic-button {
//   background-color: white;
//   color: black;
//   border: 2px solid #000000;
//   border-radius: 12px;
//   padding: 13px 55px;
//   padding-left: 10px;
//   font-family: "Work Sans";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 23px;
//   margin-top: 15px;
//   margin-right: 18px;
//   text-transform: lowercase;
//   width: 230px;
//   text-align: left;
// }

.dropdown-basic-button button {
  background-color: white;
  color: black;
  border: 2px solid #000000;
  border-radius: 12px;
  padding: 13px 55px;
  padding-left: 10px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-top: 15px;
  margin-right: 18px;
  text-transform: lowercase;
  width: 230px;
  text-align: left;
}

// .dropdown-basic-button:active {
//   border: 2px solid #315eff;
//   box-sizing: border-box;
// }

.dropdown-basic-button button:active {
  border: 2px solid #315eff;
  box-sizing: border-box;
}

.dropdown-basic-button-item {
  padding: 15px 55px;
  padding-left: 10px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  width: 220px;
}
.dropdown-basic-button-item:hover {
  background-color: #d5deff;
}
#scheduling-icon-question {
  margin-left: 15px;
}

.No-Activities {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
}

#preference-input {
  list-style-type: disc;
}

// THIS MESSED EVERYTHING UP!!!!!! USED TO BE AN ID!!!!!
// .Rectangle--Middle {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 843px;
//   height: 240px;
//   background-color: #ffffff;
//   box-sizing: border-box;
//   padding: 48px;
//   margin: 15px;
// }

button.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffff;
  background: #ffffff;
  // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

button.accordion-button:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0.25rem rgb(255 255 255 / 25%);
  background-color: #ffff;
  background: #ffffff;
}

.accordion-item {
  background-color: #f8f8f8;
}

#background-box {
  border-radius: 8px;
  border: 4px solid #f8f8f8;
}

.accordion-body {
  margin-left: 57px;
}

.dropdown-menu.show {
  border: 4px solid #315eff;
  border-radius: 12px;
}
