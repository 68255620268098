/** @format */

@use '../../Typography/typography.scss' as *;
@use '../../reset.scss' as *;
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Activity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;

  &__Header {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #000000;
    margin-top: 100px;
  }

  &__Sub-Header {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin: 50px 0 50px 0;
  }

  &__Input--Row {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    width: 800px;
  }

  &__Input--Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 70px;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 12px;
    padding: 5px;
    margin: 10px;

    &:hover {
      cursor: pointer;
      background: #eef4ff;
    }
  }

  &__Input--Iconify {
    font-size: 35px;
    margin-right: 10px;
  }

  &__Input--Wrapper--New {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 70px;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 12px;
    padding: 5px;
    margin: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__Input--Label {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }

  &__Input--Input {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    color: #000000;
    background-color: #ffffff;
    border: none;
    margin-left: 8px;

    &:hover {
      background: #eef4ff;
    }
  }
  &__Input--Input--New {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    color: #000000;
    background-color: #ffffff;
    border: none;
    margin-left: 8px;
  }

  &__Input--Text-Input {
    position: relative;
    width: 810px;
    height: 67px;
    border-radius: 10px;
    border: 2px solid #000000;
    padding-left: 20px;
    margin-top: 20px;

    &:focus {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-align: left;
      outline: none;
      border: 2px solid #315eff;
      border-radius: 12px;
    }

    &::placeholder {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-align: left;
      color: #555151bd;
    }
  }

  &__Input--SmileyFace {
    position: absolute;
    transform: translate(-65px, 35px);
    display: inline-block;
  }
  &__Input--SmileyFace:hover {
    cursor: pointer;
  }

  &__Link--Button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    width: 398px;
    height: 60px;
    background: #000000;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 50px;

    &:hover {
      color: #ffffff;
    }
  }

  &__Link--Button-Footer-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  &__Link--Button-Footer-Text {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin-right: 5px;
  }
}

.Create-New-Activity {
  display: flex;
  justify-content: center;
  color: #ffffff;
  background-color: #000000;
}

.Selected {
  background-color: #eff3ff;
  border: 4px solid #315eff;
  border-radius: 12px;
}

.ThreeSelected:hover {
  background-color: #FDF1F3;
  border: 6px solid #ED4967;
  border-radius: 12px;
  cursor: not-allowed;
}

// .BG_Blue {
//   background-color: #eff3ff;
// }

// .BG_Red {
//   background-color: #FDF1F3;
// }

.Navbar__dropdown--Hamburger {
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}
