/** @format */
@use '../../Typography/typography.scss' as *;
@use './reset.scss';
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");

body {
  background-image: url(./Assets/blob-01-1.svg);
  background-size: cover;
  background-position: top;
  background-position-y: -75px;
  height: 20vh;
  background-color: #ffffff;
  background-repeat: no-repeat;
}
.home-h3 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.2px;

  color: #000000;
}
.home-h4 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.025em;
  text-align: left;
  padding-left: 50px;
  margin-top: -10%;

  color: #000000;
}

.home-h5 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  padding-bottom: 20px;

  color: #000000;
}

.home-heading {
  font-family: "Dela Gothic One";
  font-style: normal;
  font-weight: normal;
  font-size: 84px;
  line-height: 104px;
  text-align: left;
  color: #000000;
  margin-bottom: 50px;
}

.home-h2 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;

  color: #000000;
}

.home-h6 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.2px;

  color: #000000;
}
/* .button-home-page {
  border: 10px solid #0000;
  /* padding: 0 80px; */
/* border-radius: 30px;
  margin-top: 4%;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
} */

.home-p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  margin-top: 20%;
  /* or 31px */

  color: #000000;
}
.home-hero-p {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;
  /* or 39px */

  color: #000000;
}
.home-p-bottom-page {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 350px;
  margin-left: 27.5%;
  text-align: left;
  color: #000000;
}
.overlook-of-projects {
  margin: 80px 0;
}
.project-overwiew-container {
  display: block;
  margin: 0 auto;
  padding: 4px 20px;
  border-radius: 10px;
  max-width: 90%;
}
.project-overview-description {
  border: 50px solid transparent;
  border-radius: 30px;
  border-top: 0;
}

.box-image-2,
.box-image-3,
.box-image-4 {
  width: 430px;
  height: 376px;
  background-color: #c4c4c4;
}
.box-image-2,
.box-image-4 {
  margin-top: 30px;
}
.col-md-5 {
  margin-bottom: 350px;
}
.bottom-home-page {
  margin-top: -150px;
  text-align: center;
}
.button-home-page {
  background: #000000;
  width: 480px;
  height: 75px;
  border: 1px solid #000000;
  border-radius: 30px;
}
.copyright-p {
  margin-top: 200px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */
  text-align: center;
  letter-spacing: 0.2px;

  color: #000000;
}
.vl {
  border-left: 2px solid #dedede;
  line-height: normal;
}

.three-step-overview {
  margin-top: 100px;
  padding-bottom: -300px;
  padding-top: 150px;
}

.googleLogInOut {
  margin-top: 40px;
}

.google__logo--img {
  width: 50px;
  margin-right: 30px;
}

.lowerGoogle__login--container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.LandingImage {
  margin-top: 40px;
  max-width: 100%;
  position: absolute;
  left: 50.3%;

  top: 19.2%;
  bottom: 2.8%;
}
.step1,
.step2 {
  /* position: absolute; */
  width: 80px;
  height: 80px;
  margin-left: -9%;
  /* left: 150.8px;
  top: 993px; */
}

.landing-body {
  margin-top: 21%;
}
